import * as actionTypes from './actionType';
const initialState = {
  charityObject:null
};

const reducer=(state=initialState,action)=>{
  switch(action.type){
    case actionTypes.CHARITY_OBJECT:
      return {
        ...state,
        charityObject: action.value
      };
    default:
      return state;
  }
};

export default reducer;
