/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 4:59 PM
 */

import * as actionTypes from "./actionType";
import AuthService from '../../../services/auth';
import Cookies from "js-cookie";
import {spinnerHandler} from '../spinner/action';
import * as constant from "../../../configs/constant";
import {ADMIN_PRO_PIC, ROLE_ADMIN, ROLE_FINANCE, ROLE_MODERATOR, USER_ROLE} from "../../../configs/constant";


export const loginUserSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_USER_SUCCESS,
    value: data
  }
};
export const loginUserUnsuccessfull = (data) => {
  return {
    type: actionTypes.LOGIN_USER_UNSUCCESSFULL,
    value: data
  }
};
export const loginUserFailed = (data) => {
  return {
    type: actionTypes.LOGIN_USER_FAILED,
    value: data
  }
};
export const setAuth = (data) => {
  return {
    type: actionTypes.SET_AUTH,
    value: data
  }
};
export const resetWarningHandler = (data) => {
  return {
    type: actionTypes.RESET_WARNING,
    value: data
  }
};
export const loadingHandler = (data) => {
  return {
    type: actionTypes.IS_LOADER,
    value: data
  }
};

export function loginUser(userCredentials) {
  return dispatch => {
    dispatch(spinnerHandler(true));
    AuthService.loginUser(userCredentials)
      .then((response) => {
        if (response.access_token) {
          let userRole = response.user.authorities[0].authority;
          Cookies.set(constant.ACCESS_TOKEN,response.access_token);
          Cookies.set(constant.REFRESH_TOKEN,response.refresh_token);
          Cookies.set(constant.USER_ROLE,userRole);

          if(response.user.userDetails) {
            if(response.user.userDetails.moderator_logo) {
              Cookies.set(constant.ADMIN_PRO_PIC,response.user.userDetails.moderator_logo);
            }else if(response.user.userDetails.profile_image) {
              Cookies.set(constant.ADMIN_PRO_PIC,response.user.userDetails.profile_image);
            }else {
              Cookies.remove(constant.ADMIN_PRO_PIC);
            }
            Cookies.set(constant.ADMIN_NAME, userRole === ROLE_MODERATOR ? response.user.userDetails.moderator_name : userRole === ROLE_FINANCE ? response.user.userDetails.organization_name : "Admin" )
          }else{
            Cookies.remove(constant.ADMIN_PRO_PIC);
            Cookies.set(constant.ADMIN_NAME, userRole === ROLE_MODERATOR ? "Moderator" : userRole === ROLE_FINANCE ? "Finance" : "Admin")
          }
          dispatch(loginUserSuccess(response.access_token));
        } else {
          dispatch(loginUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(loginUserFailed({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
        dispatch(spinnerHandler(false));
      })
  };
}
export function logoutUser() {
  return dispatch => {
    dispatch(spinnerHandler(true));
    AuthService.logoutUser()
      .then((response) => {
        if (response.success) {
          // dispatch(logoutUserSuccess(response));
        } else {
          // dispatch(logoutUserSuccess(response));
        }
      })
      .finally(fin=>{
        Cookies.remove(constant.ACCESS_TOKEN);
        Cookies.remove(constant.REFRESH_TOKEN);
        dispatch(spinnerHandler(false));
      })
  };
}
export const logoutUserSuccess = (data) => {
  return {
    type: actionTypes.LOGOUT_USER_SUCCESS,
    value: data
  }
};
export const logoutUserUnsuccessfull = (data) => {
  return {
    type: actionTypes.LOGOUT_USER_UNSUCCESSFULL,
    value: data
  }
};

// activate-email
export const activateUserSuccess = (data) => {
  return {
    type: actionTypes.ACTIVATE_USER_SUCCESS,
    value: data
  }
};
export const activateUserUnsuccessfull = (data) => {
  return {
    type: actionTypes.ACTIVATE_USER_UNSUCCESSFULL,
    value: data
  }
};
export function activateEmailHandler(data) {
  return dispatch => {
    AuthService.activeEmail(data)
      .then((response) => {
        if (response.success) {
          dispatch(activateUserSuccess(response));
        } else {
          dispatch(activateUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(activateUserUnsuccessfull({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
      })
  };
}
// activate - user email
export function activateUserEmailHandler(data) {
  return dispatch => {
    AuthService.activateUserEmail(data)
      .then((response) => {
        if (response.success) {
          dispatch(activateUserSuccess(response));
        } else {
          dispatch(activateUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(activateUserUnsuccessfull({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
      })
  };
}

// activation - user
export const activeUserSuccess = (data) => {
  return {
    type: actionTypes.ACTIVATE_USER_EMAIL_SUCCESS,
    value: data
  }
};
export const activeUserUnsuccessfull = (data) => {
  return {
    type: actionTypes.ACTIVATE_USER_EMAIL_UNSUCCESSFULL,
    value: data
  }
};
export function activateUserHandler(data) {
  return dispatch => {
    dispatch(spinnerHandler(true));
    AuthService.activateUser(data)
      .then((response) => {
        if (response.success) {
          dispatch(activeUserSuccess(response));
        } else {
          dispatch(activeUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(activeUserUnsuccessfull({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
        dispatch(spinnerHandler(false));
      })
  };
}

// reset password confirmation
export function resetPasswordConfirmEmail(data) {
  return dispatch => {
    AuthService.resetPasswordConfirmEmail(data)
      .then((response) => {
        if (response.success) {
          dispatch(activateUserSuccess(response));
        } else {
          dispatch(activateUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(activateUserUnsuccessfull({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
      })
  };
}

// reset password
export function resetPassword(data) {
  return dispatch => {
    AuthService.resetPassword(data)
      .then((response) => {
        if (response.success) {
          dispatch(activeUserSuccess(response));
        } else {
          dispatch(activeUserUnsuccessfull(response));
        }
      })
      .catch((e) => {
        dispatch(activeUserUnsuccessfull({
          status: 2,
          message: "Communication Failure",
        }));
      })
      .finally(fin=>{
      })
  };
}
