/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 5:02 PM
 */

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_UNSUCCESSFULL = 'LOGIN_USER_UNSUCCESSFULL';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const SET_LOGGED_IN_STATUS = 'SET_LOGGED_IN_STATUS';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_UNSUCCESSFULL = 'LOGOUT_USER_UNSUCCESSFULL';

export const SET_AUTH = 'SET_AUTH';

export const RESET_WARNING = 'RESET_WARNING';
export const IS_LOADER = 'IS_LOADER';

// activate-email
export const ACTIVATE_USER_UNSUCCESSFULL = "ACTIVATE_USER_UNSUCCESSFULL";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";

// activate-user-email
export const ACTIVATE_USER_EMAIL_UNSUCCESSFULL = "ACTIVATE_USER_EMAIL_UNSUCCESSFULL";
export const ACTIVATE_USER_EMAIL_SUCCESS = "ACTIVATE_USER_EMAIL_SUCCESS";
