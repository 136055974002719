/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 5/4/20
 * Time: 1:36 PM
 */
import ApiService from './apiService';

async function loginUser(userCredentials) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'token';
  apiObject.body = userCredentials;
  apiObject.urlencoded = true;
  apiObject.basePath = "v1/oauth";
  apiObject.type = "AUTH";
  return await ApiService.callApi(apiObject);
}

async function renewToken(token) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'token';
  apiObject.body = token;
  apiObject.urlencoded = true;
  apiObject.basePath = "v1/oauth";
  apiObject.type = "RENEW_TOKEN";
  return await ApiService.callApi(apiObject);
}

async function logoutUser() {
  const apiObject = {};
  apiObject.method = 'PATCH';
  apiObject.authentication = true;
  apiObject.endpoint = 'logout';
  apiObject.basePath = "v1/auth";
  apiObject.body = null;
  return await ApiService.callApi(apiObject);
}
async function activeEmail(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.endpoint = 'auth/confirm-email';
  apiObject.body = data;
  return await ApiService.callApi(apiObject);
}

async function activateUserEmail(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.body = data;
  apiObject.endpoint = 'auth/user/confirm-email';
  return await ApiService.callApi(apiObject);
}

async function activateUser(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.body = data;
  apiObject.endpoint = 'auth/user/activate';
  return await ApiService.callApi(apiObject);
}

// forgot-password
async function forgotPassword(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.body = data;
  apiObject.endpoint = 'auth/forgot-password';
  return await ApiService.callApi(apiObject);
}

// reset-password-confirm-email
async function resetPasswordConfirmEmail(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.body = data;
  apiObject.endpoint = 'auth/reset-password-confirm-email';
  return await ApiService.callApi(apiObject);
}

// reset-password
async function resetPassword(data) {
  const apiObject = {};
  apiObject.method = 'POST';
  apiObject.authentication = false;
  apiObject.body = data;
  apiObject.endpoint = 'auth/reset-password';
  return await ApiService.callApi(apiObject);
}
export default {loginUser,logoutUser,renewToken,activeEmail,activateUserEmail,activateUser,forgotPassword,resetPasswordConfirmEmail,resetPassword}
