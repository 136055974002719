import axios from 'axios';
import apiConfig from './apiConfig';
import Cookies from "js-cookie";
import {history} from "../history";
import * as constant from "../configs/constant";
import * as commonFunc from "../utility/commonFunc";
import * as qs from "qs";
import AuthService from "./auth";
import {errorSweetAlert} from "../utility/commonFunc";

async function callApi(apiObject) {
  let body = {};
  let headers;
  let method = apiObject.method ? apiObject.method.toLowerCase() : 'get';
  if (method === 'post' || method === 'put' || method === 'patch') {
    body = apiObject.body ? apiObject.body : {};
  }

  headers = {
    'Content-Type': apiObject.urlencoded ? 'application/x-www-form-urlencoded' : apiObject.multipart ? 'multipart/form-data':'application/json',
  };
  if (apiObject.authentication) {
    headers.Authorization = `Bearer ${Cookies.get(constant.ACCESS_TOKEN)}`;
  }
  if(apiObject.type === "AUTH"){
    headers.Authorization = `Basic ${constant.SECRET_KEY}`;
  }

  let serverUrl = apiConfig.serverUrl;
  let basePath = apiConfig.basePath;

  if (apiObject.basePath){
    basePath = apiObject.basePath;
  }

  //todo remove after get the access to charity request
   //headers.Authorization = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiI5NDc3ODUzMzkzNSIsInNjb3BlIjpbInJlYWQiLCJ3cml0ZSIsInRydXN0Il0sImRvbWFpbiI6Imh0dHA6Ly9teWRvbmF0ZS1hcHAuY2V5ZW50cmEubGsvZG9uYXRpb25wbGF0Zm9ybSIsImV4cCI6MTY2NDM2NDQxNCwidXNlciI6eyJwYXNzd29yZCI6bnVsbCwidXNlcm5hbWUiOiI5NDc3ODUzMzkzNSIsImF1dGhvcml0aWVzIjpbeyJhdXRob3JpdHkiOiJST0xFX1JFR0lTVEVSRURfRE9OT1IifV0sImFjY291bnROb25FeHBpcmVkIjp0cnVlLCJhY2NvdW50Tm9uTG9ja2VkIjp0cnVlLCJjcmVkZW50aWFsc05vbkV4cGlyZWQiOnRydWUsImVuYWJsZWQiOnRydWUsInVzZXJJZCI6MywidXNlckRldGFpbHMiOnsiaWQiOjMsImZpcnN0X25hbWUiOiJUaGFyaW5kdSIsImxhc3RfbmFtZSI6IkF0aHVrb3JhbGEiLCJlbWFpbCI6InRoYXJpbmR1YXRodWtvcmFsYTFAZ21haWwuY29tIiwibW9iaWxlIjoiOTQ3Nzg1MzM5MzUiLCJnZW5kZXIiOm51bGwsInByb2ZpbGVfaW1hZ2VfdXJsIjpudWxsLCJzdGF0dXMiOiJBQ1RJVkUifX0sImF1dGhvcml0aWVzIjpbIlJPTEVfUkVHSVNURVJFRF9ET05PUiJdLCJqdGkiOiJlYTA4ZWYyNC0yN2RhLTQwM2YtYWJmZC05NTQ0MWQxM2FkMTEiLCJjbGllbnRfaWQiOiJyZWdpc3RlcmVkX2Rvbm9yIn0.X8OoMoyx5snfX44rqgOvMOTd_cbLVD63cNgTQx8yv8o";

  const url = `${serverUrl}/${basePath}/${apiObject.endpoint}`;
  let result;

  await axios[method](url, method !== 'get' && method !== 'delete' ? body:{headers: headers} , {headers: headers})
    .then(async response => {
      result = {...await response.data, status: response && response.status ? response.status : 0} ;
    })
    .catch(async error => {
      if (error !== undefined) {
        if (error.response === undefined) {
          result = await {
            success: false, status: 2,data: null,
            message: "Your connection was interrupted"
          }
        } else if (error.response.status === 401) {

          if (apiObject.type === "RENEW_TOKEN") {
            result = await {
              success: false,
              status: 2,
              message: "Your session expired! Please login again..",
              data: null,
            };
          } else if (apiObject.type === "AUTH") {
            result = await {
              success: false, status: 0,data: null,
              message: error.response.data.message
            };
          } else {
            result = await renewTokenHandler(apiObject);
          }
        } else if (error.response.status === 403) {
          result = await  {
            success: false, status: 2,data: null,
            message: "Access is denied."
          };
        } else if (error.response.status === 417) {
          result = await  {
            success: false, status: 2,data: null,
            message: "Oops! Something went wrong."
          };
        } else if(error.response.data !== undefined){
          result = await {
            success: false, status: 0,data: null,
            message: error.response.data.errorContent ? error.response.data.errorContent : 'Sorry, something went wrong'
          }
        } else {
          result = await  {
            success: false, status: 2,data: null,
            message: "Sorry, something went wrong."
          };
        }
      } else {
        result = await  {
          success: false, status: 2,data: null,
          message: "Your connection was interrupted!"
        };
      }
    });

  return result;
}
async function normalAPI(apiObject) {
  let method = apiObject.method ? apiObject.method.toLowerCase() : 'get';
  let result;

  await axios[method](apiObject.endpoint)
    .then(async response => {
      result = {...await response.data,success: true};
    })
    .catch(async () => {
      result = await {
        success: false, status: 2,data: null,
        message: "Your connection was interrupted"
      }
    });

  return result;
}
async function multipleRequestAPIs(array) {
  let result;

  await axios.all(array)
    .then(async response => {
      result = {...await response,success: true};
    })
    .catch(async error => {
      result = await {
        success: false, status: 2,data: null,
        message: error? error.response? error.response.message : "Your connection was interrupted" : "Your connection was interrupted"
      }
    })
    .finally(fin => {

    });
  return result;
}
export const renewTokenHandler = async (apiObject) => {
  let result;
  // renew token - start
  const obj = {refresh_token: Cookies.get(constant.REFRESH_TOKEN), grant_type: 'refresh_token'};
  await AuthService.renewToken(qs.stringify(obj))
    .then(async response => {
      if (response.access_token) {
        Cookies.set(constant.ACCESS_TOKEN,response.access_token);
        Cookies.set(constant.REFRESH_TOKEN,response.refresh_token);
        result = await callApi(apiObject);
      } else {
        result = await response;
        errorSweetAlert(
          response.message,
          '',
          'Login',
          () => {
            Cookies.remove(constant.ACCESS_TOKEN);
            Cookies.remove(constant.REFRESH_TOKEN);
            window.location = `${constant.BASE_ROUTE_PATH}${constant.ROUTE_LOGIN}`;
          },
        );
      }
    });
  // renew token - end
  return result;
};
export default {callApi,normalAPI,multipleRequestAPIs};
