import apiConfig from "../services/apiConfig";

/**
 * Created by WebStorm.
 * User: athukorala
 * Date: 4/17/22
 * Time: 3:15 PM
 */

export const ACCESS_TOKEN = "ff5a22e5d11bc954de19ceef8c3813490";
export const REFRESH_TOKEN = "6c66b0";
export const USER_ROLE = "IoEGklllEl";
export const SECRET_KEY = "YWRtaW46";

//URLs
export const CHARITY_PROGRAM_BASE_URL = `${apiConfig.serverUrl}/v1/program`
export const RESOURCE_SERVER_URL = `https://resources.karuna.lk`;
export const HEALTHCARE_ICON = `${RESOURCE_SERVER_URL}/category/icon/healthcare.png`;

export const ADMIN_PRO_PIC = "ADMIN_PRO_PIC";
export const ADMIN_NAME = "ADMIN_NAME";

export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_MODERATOR = "ROLE_MODERATOR";
export const ROLE_FINANCE = "ROLE_FINANCE_REVIEWER";

export const BASE_ROUTE_PATH = "";
// export const BASE_ROUTE_PATH = "/b1698d9342a8ce68e37ee7c356174f3e3f22d7a1";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_LOGIN = "/login";
export const ROUTE_MODERATORS = "/moderators";
export const ROUTE_MODERATOR_PROFILE = "/moderator/profile";
export const ROUTE_MODERATOR_CHANGE_PASSWORD = "/moderator/change-password";
export const ROUTE_ADD_MODERATORS = "/moderators/add";
export const ROUTE_FINANCE = "/finance";
export const ROUTE_ADD_FINANCES = "/finance/add";
export const ROUTE_CHARITIES = "/charities";
export const ROUTE_CHARITY_MANAGE = "/charities/manage";
export const ROUTE_CATEGORIES_MANAGE = "/categories/manage";
export const ROUTE_CHARITY_PROGRAMS = "/charity-programs";
export const ROUTE_CHARITY_PROGRAMS_MANAGE = "/charity-programs/manage";
export const ROUTE_COM_REC_LIST = "/community-recommendations";
export const ROUTE_FEATURED_MANAGE = "/featured-programs/manage";
export const ROUTE_THIRD_PARTY_LISTINGS_MANAGE = "/third-party-listings/manage";
export const ROUTE_COM_REC_APPROVALS = "/community-recommendation-approvals";
export const ROUTE_CATEGORY_APPROVALS = "/category-approvals";
export const ROUTE_INCOME_REPORT = "/report/general";
export const ROUTE_PACKAGE_REPORT = "/report/package";
export const ROUTE_RECONCILIATION_REPORT = "/report/reconciliation";
export const ROUTE_NEW_RECONCILIATION_REPORT = "/report/new-reconciliation";
export const ROUTE_DAILY_DONATE_REPORT = "/report/daily-donate-slips";

export const JOBS_CONST = "jobs";
export const DEALS_CONST = "deals";
export const NEWS_CONST = "news";

export const HIDE_STS = "HIDDEN";
export const SHOW_STS = "SHOW";
export const DELETED_STS = "DELETED";

export const INACTIVE_STATUS = "INACTIVE";
export const ACTIVE_STATUS = "ACTIVE";

// charity status
export const CHARITY_BLOCK_STATUS = "BLOCK";
export const CHARITY_ACTIVE_STATUS = "ACTIVE";
export const CHARITY_ACTIVE_PENDING = "PENDING";

export const CHARITY_TYPE_INDIVIDUAL = "INDIVIDUAL";
export const CHARITY_TYPE_ORGANIZATION = "ORGANIZATION";

// CATEGORY
export const CATEGORY_APPROVED = "ACTIVE";
export const CATEGORY_PENDING = "PENDING";
export const CATEGORY_REJECTED = "REJECTED";

// CHARITY_PROGRAMS
export const CHARITY_PRO_ACTIVE = "ACTIVE";
export const CHARITY_PRO_PENDING = "PENDING";
export const CHARITY_PRO_INACTIVE = "INACTIVE";
export const CHARITY_PRO_TERMINATED = "TERMINATED";
export const CHARITY_PRO_COMPLETED = "COMPLETED";
export const CHARITY_PRO_BLOCK = "BLOCK"; // admin | moderator
export const CHARITY_PRO_EXPIRED = "EXPIRED";

// ACTIVE, INACTIVE, PENDING, BLOCK, EXPIRED, REJECTED, TERMINATED, SUSPENDED, COMPLETED

// SESSION STORAGE KEYS
export const SESSION_SEARCH = "SESSION_SEARCH"
export const SESSION_STATUS = "SESSION_STATUS"
export const SESSION_PAGE = "SESSION_PAGE"
export const SESSION_TOTAL_NO_OF_PAGE = "SESSION_TOTAL_NO_OF_PAGE"


//PROGRAM TYPE

export const PROGRAM_CHARITY= "Charity Program";
export const PROGRAM_COMMUNITY_RECOMMENDATION = "Community Recomendation";

