import React from "react"
import * as Icon from "react-feather"
import * as constant from "./constant"
import {
  ROLE_ADMIN, ROLE_FINANCE,
  ROLE_MODERATOR
} from "./constant";
const navigationConfig = [
  {
    id: "1",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Monitor size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_FINANCE],
    navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_DASHBOARD
  },
  {
    id: "60",
    title: "System Users",
    type: "collapse",
    icon: <Icon.Users size={20}/>,
    permissions: [ROLE_ADMIN],
    children: [
      {
        id: "2",
        title: "Moderators",
        type: "collapse",
        icon: <Icon.User size={20}/>,
        permissions: [ROLE_ADMIN],
        children: [
          {
            id: "3",
            title: "View All",
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: [ROLE_ADMIN],
            navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_MODERATORS,
          },
          {
            id: "4",
            title: "Create",
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: [ROLE_ADMIN],
            navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_ADD_MODERATORS,
          }
        ]
      },
      {
        id: "5",
        title: "Finance Reviewers",
        type: "collapse",
        icon: <Icon.User size={20}/>,
        permissions: [ROLE_ADMIN],
        children: [
          {
            id: "6",
            title: "View All",
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: [ROLE_ADMIN],
            navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_FINANCE,
          },
          {
            id: "7",
            title: "Create",
            type: "item",
            icon: <Icon.Circle size={12} />,
            permissions: [ROLE_ADMIN],
            navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_ADD_FINANCES,
          }
        ]
      },
    ]
  },
  {
    id: "8",
    title: "Charities",
    type: "item",
    icon: <Icon.Heart size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_MODERATOR],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CHARITIES,
  },
  {
    id: "9",
    title: "Charity Programs",
    type: "item",
    icon: <Icon.List size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_MODERATOR],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CHARITY_PROGRAMS,
  },
  {
    id: "10",
    title: "C.R. List",
    type: "item",
    icon: <Icon.List size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_MODERATOR],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_COM_REC_LIST,
  },
  {
    id: "11",
    title: "C.R. Approvals",
    type: "item",
    icon: <Icon.Coffee size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_MODERATOR],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_COM_REC_APPROVALS,
  }, {
    id: "12",
    title: "Program Lists",
    type: "collapse",
    icon: <Icon.Filter size={20}/>,
    permissions: [ROLE_ADMIN],
    // navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_FEATURED_MANAGE,
    children: [
      {
        id: "13",
        title: "Featured Programs",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: [ROLE_ADMIN],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_FEATURED_MANAGE,
      },
      {
        id: "14",
        title: "Third Party Listings",
        type: "item",
        icon: <Icon.Circle size={12}/>,
        permissions: [ROLE_ADMIN],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_THIRD_PARTY_LISTINGS_MANAGE,
      }
    ]
  },
  {
    id: "15",
    title: "Manage Categories",
    type: "item",
    icon: <Icon.Edit size={20}/>,
    permissions: [ROLE_ADMIN],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CATEGORIES_MANAGE,
  }, {
    id: "16",
    title: "Category Approvals",
    type: "item",
    icon: <Icon.Grid size={20}/>,
    permissions: [ROLE_ADMIN, ROLE_MODERATOR],
    navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_CATEGORY_APPROVALS,
  },
  {
    id: "17",
    title: "Reports",
    type: "collapse",
    icon: <Icon.PieChart size={20}/>,
    permissions: [ROLE_ADMIN,ROLE_FINANCE],
    children: [
      {
        id: "18",
        title: "General Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_ADMIN,ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_INCOME_REPORT,
      },
      {
        id: "19",
        title: "Reconciliation Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_ADMIN,ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_RECONCILIATION_REPORT,
      },
      {
        id: "20",
        title: "Package Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_ADMIN,ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_PACKAGE_REPORT,
      },
      {
        id: "21",
        title: "Daily Donate Slips Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_ADMIN,ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_DAILY_DONATE_REPORT,
      }
    ]
  },
  {
    id: "22",
    title: "Additional Settings",
    type: "collapse",
    icon: <Icon.Settings size={20}/>,
    permissions: [ROLE_MODERATOR, ROLE_FINANCE],
    children: [
      {
        id: "23",
        title: "Profile",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_MODERATOR, ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH + constant.ROUTE_MODERATOR_PROFILE,
      },
      {
        id: "24",
        title: "Change Password",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [ROLE_MODERATOR, ROLE_FINANCE],
        navLink: constant.BASE_ROUTE_PATH+constant.ROUTE_MODERATOR_CHANGE_PASSWORD,
      }
    ]
  },
];

export default navigationConfig
