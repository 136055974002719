/* eslint-disable*/
import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import * as constant from "../../../configs/constant"
import * as actionCreator from "../../../store/domain/auth/action";
import {
  connect,
} from 'react-redux';
import * as commonFunc from "../../../utility/commonFunc";
import CorporateImage from "../../../assets/img/views/profile/pro-pic.png";
import swal from "sweetalert";
import Cookies from "js-cookie";

const signoutHandler = (props) => {
  swal({
    title: commonFunc.sureTxt,
    // icon: warningIcon,
    closeOnClickOutside: false,
    buttons: {
      cancel: 'No',
      dangerMode: {
        text: "Yes",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {
        case "action":
           props.logoutUser();
          Cookies.remove(constant.ACCESS_TOKEN);
          history.push(constant.BASE_ROUTE_PATH+"/login")
          break;
        default:
      }
    })
};

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      {/*<DropdownItem*/}
        {/*tag="a"*/}
        {/*href="#"*/}
        {/*onClick={e => handleNavigation(e, constant.BASE_ROUTE_PATH+'/profile')}*/}
      {/*>*/}
        {/*<Icon.User size={14} className="mr-50" />*/}
        {/*<span className="align-middle">Profile</span>*/}
      {/*</DropdownItem>*/}

      {/*<DropdownItem divider />*/}

      <DropdownItem
        tag="a"
        href="#"
        onClick={()=>signoutHandler(props)}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  )
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.status !== -1){
      // commonFunc.notifyMessage(nextProps.message,nextProps.status)
      this.props.resetHandler();
    }
    if(!nextProps.loggedInStatus){
      history.push(constant.BASE_ROUTE_PATH+"/login")
    }
  }

  // d-none
  render() {
    let proPic = Cookies.get(constant.ADMIN_PRO_PIC);
    let adminName = Cookies.get(constant.ADMIN_NAME);
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex ">
              <span className="user-name text-bold-600">{adminName ? adminName : "Admin"}</span>
            </div>
            <span data-tour="user">
              <img
                src={proPic ? proPic : CorporateImage}
                className="half-round nav-logo"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
const mapStateToProps = (state) => ({
  loggedInStatus: state.auth.loggedInStatus,
  status: state.auth.status,
  message: state.auth.message,
  isSpinner: state.spinner.isSpinner,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: userCredentials => dispatch(actionCreator.logoutUser(userCredentials)),
  resetHandler: () => dispatch(actionCreator.resetWarningHandler())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);
