import swal from "sweetalert";
import Swal from "sweetalert2";
import toastr from 'toastr';
import Cookies from "js-cookie";
import * as constant from "../configs/constant";
import React from "react";
import {Badge} from "reactstrap";
import {Edit, Trash2} from "react-feather";
import FileType from "file-type/browser";
import moment from "moment";
import {CHARITY_PRO_ACTIVE, CHARITY_PRO_COMPLETED, CHARITY_PRO_TERMINATED} from "../configs/constant";
import XLSX from "xlsx";
const Compress = require("compress.js").default;

export const sureTxt = "Are you sure you want to do this?";

export const notifyMessage = (msg, type, duration) => {
  let msgType = "error";

  if (type === 0) {
    msgType = "error"
  } else if (type === 1) {
    msgType = "success"
  } else if (type === 2) {
    msgType = "warning"

  }
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": true,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": true,
    "onclick": null,
    "showDuration": "2500",
    "hideDuration": "2500",
    "timeOut": duration === undefined ? "2500" : duration,
    "extendedTimeOut": "2500",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };
  toastr[msgType](msg === undefined || msg === null ? "Please enter correct details" : msg, type === 0 ? 'Error' : type === 1 ? 'Success' : type === 2 ? 'Warning' : "Error")
};

export const onFileError = (error) => {
  notifyMessage(error.message,0);
};

export function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

//sw alert
export const warningAlert = (msg, type, redirect) => {
  swal({
    title: msg,
    // icon: type === 0 ? 'success' : 'error',
    closeOnClickOutside: false,
    buttons: {
      dangerMode: {
        text: "Okay",
        value: "action",
        className: "okay-btn"
      }
    }
  })
    .then((value) => {
      switch (value) {

        case "action":
          break;
        default:
      }
    })
};

export const successSweetAlert = (title, text, confirmBtnText,confirmButtonAction) => {
  const customizedWarningSweetAlert = Swal.mixin({
    customClass: {
      header: 'custom-sweet-alert-header',
      confirmButton: 'btn custom-sweet-alert-warning-confirm-btn',
      container: 'custom-sweet-alert-container',
      popup: 'custom-sweet-alert-popup',
      actions: 'custom-sweet-alert-actions',
      cancelButton: 'btn custom-sweet-alert-warning-cancel-button',
      // cancelButton: 'btn custom-sweet-alert-warning-cancel-button'
    },
    buttonsStyling: false
  })

  customizedWarningSweetAlert.fire({
    icon: "success",
    title: title ? title : null,
    text: text ? text : null,
    showCancelButton: false,
    confirmButtonText: confirmBtnText,
    reverseButtons: false
  }).then((result) => {
    if (result.isConfirmed) {
      confirmButtonAction && confirmButtonAction();
    }
  })
}

export const errorSweetAlert = (title, text, confirmBtnText,action) => {
  const customizedWarningSweetAlert = Swal.mixin({
    customClass: {
      header: 'custom-sweet-alert-header',
      confirmButton: 'btn custom-sweet-alert-warning-confirm-btn',
      container: 'custom-sweet-alert-container',
      popup: 'custom-sweet-alert-popup',
      actions: 'custom-sweet-alert-actions',
      cancelButton: 'btn custom-sweet-alert-warning-cancel-button',
      // cancelButton: 'btn custom-sweet-alert-warning-cancel-button'
    },
    buttonsStyling: false
  })

  customizedWarningSweetAlert.fire({
    icon: "error",
    title: title ? title : null,
    text: text ? text : null,
    showCancelButton: false,
    confirmButtonText: confirmBtnText,
    reverseButtons: false
  }).then((result) => {
    if (result.isConfirmed) {
      if (action!==undefined){
        action()
      }
    }
  })
}

export const warningSweetAlert = (icon, title, text, confirmBtn, confirmBtnText, cancelBtn, cancelBtnText) => {
  const customizedWarningSweetAlert = Swal.mixin({
    customClass: {
      container: 'custom-sweet-alert-container',
      popup: 'custom-sweet-alert-popup',
      header: 'custom-sweet-alert-header',
      actions: 'custom-sweet-alert-actions',
      confirmButton: 'btn custom-sweet-alert-warning-confirm-btn',
      cancelButton: 'btn custom-sweet-alert-warning-cancel-button',

    },
    buttonsStyling: false
  })

  customizedWarningSweetAlert.fire({
    imageUrl: icon,
    title: title,
    text: text,
    showCancelButton: true,
    cancelButtonText: cancelBtnText,
    confirmButtonText: confirmBtnText,
    reverseButtons: false
  }).then((result) => {
    if (result.isConfirmed) {
      confirmBtn()
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      cancelBtn()
    }
  })
}

export const formatCurrency = (val) => {
  val = val ? val : 0;
  // return val.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '1,');
  return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const checkAuthHandler = async () => {
  let isAuth = await Cookies.get(constant.ACCESS_TOKEN);
  return !!isAuth;
};
export const compressImage = async (files,returnType) => {
  const compress = new Compress();

  return await compress.compress(files, {
    size: 4,
    quality: 0.75,
    maxWidth: 1920,
    maxHeight: 1920,
    resize: true
  })
    .then(async results => {
      const img1 = results[0];
      if(returnType==='Base64')return (img1.prefix + img1.data)
      else return await Compress.convertBase64ToFile(img1.data, img1.ext)
    })
};

export const getTableHeader = (status) => {
  if(status === "MODERATORS") {
    return [
      {
        name: "USERNAME", selector: "username", sortable: true, minWidth: "200px",
        cell: row => (
          <p className="text-bold-500 text-truncate mb-0">{row.username}</p>
        )
      },
      {
        name: "EMAIL", selector: "email", sortable: true,
        cell: row => (
          <p className="text-bold-500 text-truncate mb-0">{row.email}</p>
        )
      },
      {
        name: "MOBILE", selector: "mobile", sortable: true,
        cell: row => (
          <p className="text-bold-500 text-truncate mb-0">{row.mobile}</p>
        )
      },
      {
        name: "STATUS", selector: "status", sortable: true,
        cell: row => (
          <Badge
            color={row.status === "inactive" ? "light-danger" : "light-success"} pill>
            {row.status}
          </Badge>
        )
      },
      {
        name: "ACTIONS", selector: "", sortable: false,
        cell: row => (
          <div>
            <Edit size="15" />
            <Trash2 size="15" />
          </div>
        )
      }
    ];
  }
  return <tr>
    <th>Id</th>
    <th>Title</th>
    <th className={"tbl-width-130"}>Description</th>
    <th>Status</th>
    <th>Category</th>
    <th>Expiry Date</th>
    <th className={"align-center_"}>Show|Hide</th>
    <th className={"align-center_"}>Update</th>
    <th className={"align-center_"}>Delete</th>
  </tr>;
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function() {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}

export const customPlaceholder = (selectedCountryPlaceholder, selectedCountryData) => {
  // return selectedCountryData.dialCode + "XXXXXXXXX";
  return "";
};
export const valueAppendHandler = (val) => {
  return parseInt(val) <= 9 && parseInt(val) >= 0 ? `0${val}` : val;
}

export const dataURLtoFile = (dataurl) => {
  if (dataurl !== null && dataurl !== '') {
    let filename = 'file.' + dataurl.split(';base64')[0].split('data:')[1].split('/')[1];
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}

export const getExtension = (filename) => {
  let parts = filename.split('.');
  return parts[parts.length - 1];
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

export const fileReader = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const copyTextToClipboard = async(text) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text)
      .then(()=>{
        notifyMessage('Successfully copied ',1);
      })
      .catch(()=>{
        // notifyMessage('Unsuccessfully Coping, Please do it manually','warning')
      })
  } else {
    document.execCommand('copy', true, text);
  }
}

export const stringTrimFunction = (length, string) =>{
  if(length>0 && string && string.length>length){
    return `${string.substring(0, length)}...`;
  }else {
    return string
  }
}

export const getExpireStatus = (endDate, currentStatus) => {
  if(currentStatus !== CHARITY_PRO_ACTIVE) return currentStatus;
  if (new Date(moment().format('YYYY-MM-DD')) > new Date(moment(endDate).format('YYYY-MM-DD'))) {
    return 'EXPIRED';
  } else {
    return currentStatus;
  }
}
//** using for get real file extension **//
export const getRealFileExtension = (e) => {
  return new Promise(function (resolve, reject) {
    FileType.fromBlob(e).then((res) => {
      resolve(res)
    })
  }).then(function (result) {return result?.mime})
}

export const isEmpty = (value) => {
  return (
    typeof value === "undefined" || value === null || value === "" || !value
  );
};

export const downloadExel = (data,name) => {
  const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb,ws,`${name}`);
  XLSX.writeFile(wb,`${name}.xlsx`);
}
